import React, { Fragment } from "react";
import Helper from "../../assets/helper.svg";

function AskView({ loading, handleInit }) {
  return (
    <Fragment>
      <div className="h-[85%] border-b-[1px] border-b-[#DDD8D8] flex flex-col justify-center items-center px-8">
        <img src={Helper} alt="un gentil policier" className="mb-8" />
        <h2 className="font-degular text-4xl font-medium text-[#3C002D]">
          Besoin d'aide pour modérer des messages ?
        </h2>
      </div>
    </Fragment>
  );
}

export default AskView;
