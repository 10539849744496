import axios from "axios";

const url = process.env.REACT_APP_API_URL;
//const url = 'http://localhost:8080'

/**
 * @returns {Promise<{runId: string; threadId: string}>}
 */
export const initThread = async () => {
  const response = await axios.post(`${url}/itinerary`, {
    text: "J'aimerais visiter Paris",
  });
  return response.data;
};

export const getMessages = async (threadId) => {
  const response = await axios.get(`${url}/itinerary/messages/${threadId}`);
  return response.data;
};

export const sendNewMessage = async (threadId, userMsg) => {
  const response = await axios.post(`${url}/itinerary/messages/${threadId}`, {
    text: userMsg,
  });
  console.log(response);
  return response.data;
};

export const checkMsg = async (msg) => {
  const response = await axios.post(`${url}/moderation`, {
    text: msg,
  });
  console.log(response);
  return response.data.results[0];
};
