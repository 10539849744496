import React, { Fragment, useRef, useEffect } from "react";
import RightMsg from "../../ui/RightMsg";
import LeftMsg from "../../ui/LeftMsg";

function ChatView({ messages, loading }) {
  const chatRef = useRef(null);

  const goToChatBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop =
        chatRef.current.scrollHeight - chatRef.current.clientHeight;
    }
  };

  useEffect(() => {
    goToChatBottom();
  }, [messages]);

  return (
    <Fragment>
      <div
        className="p-8 pb-0 flex flex-col overflow-scroll border-b-[1px] border-b-[#DDD8D8] h-[85%]"
        ref={chatRef}
        id="no-scrollbar"
      >
        {messages.map((msg) => {
          if (msg.role === "user") {
            return (
              <RightMsg textMsg={msg.content[0].text.value} key={msg.id} />
            );
          } else {
            return (
              <LeftMsg
                textMsg={
                  msg.reason
                    ? `${msg.content[0].text.value} : ${msg.reason}`
                    : `${msg.content[0].text.value}`
                }
                key={msg.id}
                flagged={msg.flagged}
              />
            );
          }
        })}
        {loading && (
          <div class="flex space-x-2 justify-center items-center bg-white dark:invert h-10 w-28">
            <span class="sr-only">Loading...</span>
            <div class="h-3 w-3 bg-[black] rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div class="h-3 w-3 bg-[black] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div class="h-3 w-3 bg-[black] rounded-full animate-bounce"></div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ChatView;
