import React from "react";

function LeftMsg({ textMsg, flagged }) {
  return (
    <div className="w-full">
      <div
        className={`w-3/4 bg-[#F4F0F0] text-[#3C002D] rounded-xl rounded-bl-none p-8 mb-8 whitespace-pre-line font-degular border ${flagged ? "border-[#FF3200]" : "border-[#3CBAAA]"}`}
      >
        {flagged ? <p>❌</p> : <p>✅</p>}
        {textMsg}
      </div>
    </div>
  );
}

export default LeftMsg;
