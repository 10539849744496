import { useState } from "react";
import { checkMsg } from "./utils/api";
import { v4 as uuidv4 } from "uuid";
import AskView from "./views/AskView";
import ChatView from "./views/ChatView";
import Header from "./ui/Header";

function App() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userMsg, setUserMsg] = useState("");

  const handleSend = async () => {
    if (userMsg) {
      setMessages((prevState) => [
        ...prevState,
        { content: [{ text: { value: userMsg } }], id: uuidv4(), role: "user" },
      ]);
      setLoading(true);
      setUserMsg("");
      const moderationResponse = await checkMsg(userMsg);
      if (moderationResponse.flagged) {
        const wrongDomain = Object.keys(moderationResponse.categories).find(
          (key) => moderationResponse.categories[key] === true,
        );
        setMessages((prevState) => [
          ...prevState,
          {
            content: [
              { text: { value: "Ce message présente le problème suivant" } },
            ],
            id: uuidv4(),
            role: "assistant",
            flagged: true,
            reason: wrongDomain,
          },
        ]);
      } else {
        setMessages((prevState) => [
          ...prevState,
          {
            content: [{ text: { value: "Tout semble ok !" } }],
            id: uuidv4(),
            role: "machine",
            flagged: false,
          },
        ]);
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#F4F0F0] w-screen h-screen max-h-screen">
      <Header />
      <main className="h-[46rem] max-h-[80%] w-screen bg-[#F4F0F0]">
        <div className="bg-[#FFF] h-full w-full max-w-4xl mx-auto mt-8 rounded-b-[44px] rounded-t-[60px] flex flex-col justify-between">
          {messages.length === 0 ? (
            <AskView loading={loading} />
          ) : (
            <ChatView messages={messages} loading={loading} />
          )}
          <div className="m-8 text-[#3C002D] bg-[#F4F0F0] px-2 flex items-center rounded-md">
            <textarea
              value={userMsg}
              type="text"
              className="w-full bg-[#F4F0F0] font-medium font-degular m-4 h-6 rounded-xl resize-none flex items-center justify-center mr-2 focus-visible:outline-none"
              onChange={(event) => setUserMsg(event.target.value)}
            />
            <div
              className={`${userMsg ? "bg-[#3C002D]" : "bg-[#917E8C]"} text-[#FFF] rounded-md w-7 h-7 flex justify-center items-center cursor-pointer`}
              onClick={() => handleSend()}
            >
              <svg
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-3"
              >
                <path
                  d="M1 6L6 1L11 6M6 13V2"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
